.edit-form {
  display: grid;
  column-gap: 18px;
  grid-template-areas: 
  "personal account"
  "address address";
}

.personal {
  grid-area: personal;
}

.address {
  grid-area: address;
}

.account {
  grid-area: account;
}

.row {
  display: flex;
  gap: 4px;
  margin-left: -4px;
  flex-grow: 1;
  flex-wrap: wrap;
  /* Keeps fields aligned when invalid feedback is given. */
  align-items: flex-start; 
}
