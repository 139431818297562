.update-password-card-wrapper {
  max-width: 350px;
}

.must-container{
  font-size: smaller;
  font-style: italic;
  color: red;
}

.must-item{
  position: relative;
  height: 1.5em;
  padding: 0.2em 0;
}

.must-text{
  position: absolute;
  z-index: 0;
  top: 0.6em;
  left: 1em;

  width: 100%;

  padding: 0;
  margin: 0;
  line-height: 0;
}

.must-line{
  position: absolute;
  z-index: 1;
  top: 0.5em;
  
  width: 0;
  height: 0.2em;
  
  background-color: #EF476F;
      
  transition: 0.5s;
}

.cross-out{
  width: 100%;
}

/* misc styling for form elements */

h6{
  padding-top: 1em;
  padding-bottom: 0.5em;
  margin: 0.7em;
  line-height: 0;
  font-style: italic;
  color: red;
}