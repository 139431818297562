:root {
  /* Primary palette */
  --ub-blue: #005bbb;
  --hayes-hall-white: #ffffff;
  /* Secondary palette */
  --letchworth-autumn: #e56a54;
  --solar-strand: #ffc72c;
  --greiner-green: #ebec00;
  --lake-lasalle: #00a69c;
  --capen-brick: #990000;
  --bronze-buffalo: #ad841f;
  --olmsted-green: #6da04b;
  --niagara-whirlpool: #006570;
  --victor-e-blue: #2f9fd0;
  --harriman-blue: #002f56;
  --baird-point: #e4e4e4;
  --townsend-gray: #666666;
}

html, body {
  display: flex;
}

html, body, #root, #root>div {
  height: 100%;
  min-width: 100%;
}

.bg-ub-blue {
  background-color: var(--ub-blue);
}

.bg-townsend-gray {
  background-color: var(--townsend-gray);
}

.bg-harriman-blue {
  background-color: var(--harriman-blue);
}

.bg-baird-point {
  background-color: var(--baird-point);
}

.text-ub-blue {
  color: var(--ub-blue);
}

.text-townsend-gray {
  color: var(--townsend-gray);
}

.btn-ub-blue, .btn-ub-blue:hover {
  background-color: var(--ub-blue);
  color: var(--hayes-hall-white);
}

.btn-ub-blue:hover {
  background-color: var(--harriman-blue);
}
