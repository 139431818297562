.wrapper {
  flex-wrap: wrap;
}

.sidenav {
  width: 100%;
}

.content {
  overflow-y: auto;
  flex-grow: 1;
}

@media (min-width: 576px) {
  .wrapper {
    flex-wrap: nowrap;
  }

  .sidenav {
    width: 200px;
    min-width: 200px;
  }

}
