.navitem {
  font-size: 1.2rem;
  padding: 4px;
  color: black;
  text-decoration: inherit;
  border-radius: 4px;
}

.active, .navitem:hover {
  color: white;
  text-decoration: inherit;
  background-color: var(--townsend-gray) ;
}
