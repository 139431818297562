.brand {
  text-align: center;
  font-size:2rem;
  color: white;
}

.sidenav, .sidenav-links {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.spacer {
  flex-grow: 1;
}
