.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion:hover,
.active {
  background-color: #ccc;
}

.icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.title {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  margin: 0;
}

.content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}

.text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}